/* eslint-disable max-lines */
import { Components } from "@mui/material/styles";
import {
  bordersGrey,
  cyan,
  darkBlue,
  darkGrey,
  darkRed,
  green,
  inputBorderColorDefault,
  inputBorderColorHover,
  orange,
  primary,
  success,
  white,
} from "../colors";
import { boxShadowCard } from "../shadows";

const mediumFont = "CoHeadlineTrial-Regular !important, Arab Kufi Bold !important";

export const CustomComponentsOverride: Components = {
  MuiCssBaseline: {},
  MuiButtonBase: {
    styleOverrides: {
      root: {
        height: 40,
        fontFamily: mediumFont,
        borderRadius: 10,
      },
    },
  },
  MuiButton: {
    defaultProps: {
      variant: "contained",
    },
    styleOverrides: {
      root: {
        fontFamily: mediumFont,
        borderRadius: 10,
        boxShadow: "none",
        paddingInline: 15,
        paddingTop: 11,
        paddingBottom: 11,
        marginInline: 5,
        textTransform: "unset",
      },
      outlined: {
        backgroundColor: white,
        "&.MuiButton-outlinedPrimary": {
          "&:hover": {
            backgroundColor: primary,
            boxShadow: "none",
            color: white,
            fill: white,
          },
          "&.Mui-disabled": {
            borderColor: primary,
            color: primary,
            opacity: "50%",
          },
        },
        "&.MuiButton-outlinedSuccess": {
          "&:hover": {
            backgroundColor: success,
            boxShadow: "none",
            color: white,
            fill: white,
          },
          "&.Mui-disabled": {
            borderColor: success,
            color: success,
            opacity: "50%",
          },
        },
        "&.MuiButton-outlinedError": {
          "&:hover": {
            backgroundColor: darkRed,
            boxShadow: "none",
            color: white,
            fill: white,
          },
          "&.Mui-disabled": {
            borderColor: darkRed,
            color: darkRed,
            opacity: "50%",
          },
        },
        "&.MuiButton-outlinedInfo": {
          "&:hover": {
            backgroundColor: cyan,
            boxShadow: "none",
            color: white,
            fill: white,
          },
          "&.Mui-disabled": {
            borderColor: cyan,
            color: cyan,
            opacity: "50%",
          },
        },
        "&.MuiButton-outlinedWarning": {
          "&:hover": {
            backgroundColor: orange,
            boxShadow: "none",
            color: white,
            fill: white,
          },
          "&.Mui-disabled": {
            borderColor: orange,
            color: orange,
            opacity: "50%",
          },
        },
      },
      contained: {
        "&.MuiButton-containedPrimary": {
          backgroundColor: primary,
          color: white,
          fill: white,
          "&.Mui-disabled": {
            backgroundColor: primary,
            color: white,
            opacity: "50%",
          },
          "&:hover": {
            backgroundColor: "#19265D",
            boxShadow: "none",
          },
        },
        "&.MuiButton-containedError": {
          backgroundColor: darkRed,
          fill: white,
          color: white,
          "&.Mui-disabled": {
            backgroundColor: darkRed,
            color: white,
            opacity: "50%",
          },
          "&:hover": {
            backgroundColor: darkRed + "db",
            boxShadow: "none",
          },
        },
        "&.MuiButton-containedSuccess": {
          backgroundColor: green,
          color: white,
          fill: white,
          "&.Mui-disabled": {
            backgroundColor: success,
            color: white,
            opacity: "50%",
          },
          "&:hover": {
            backgroundColor: "#5ACB04",
            boxShadow: "none",
          },
        },
        "&.MuiButton-containedInfo": {
          backgroundColor: cyan,
          color: white,
          fill: white,
          "&.Mui-disabled": {
            backgroundColor: cyan,
            color: white,
            opacity: "50%",
          },
          "&:hover": {
            backgroundColor: "#22d5cbe0",
            boxShadow: "none",
          },
        },
        "&.MuiButton-endIcon": {
          color: "inherit",
        },
        "&.MuiButton-startIcon": {
          color: "inherit",
        },

        "&.MuiButton-containedWarning": {
          backgroundColor: orange,
          color: white,
          "&.Mui-disabled": {
            backgroundColor: orange,
            color: white,
            opacity: "50%",
          },
          "&:hover": {
            backgroundColor: "#FA6C3D",
            boxShadow: "none",
          },
        },
      },
    },
  },
  MuiIconButton: {
    styleOverrides: {
      root: {
        borderRadius: 10,
        // border: "1px solid",
        "& > svg > path": {
          // fill: "inherit",
        },
        "&.Mui-disabled": {
          opacity: "50%",
        },
        ":hover": {
          backgroundColor: "initial",
        },
      },

      // disabled: {
      //   opacity: "50%",
      // },
    },
  },
  MuiTabs: {
    styleOverrides: {
      indicator: {
        display: "none",
      },
    },
  },
  MuiTab: {
    styleOverrides: {
      root: {
        backgroundColor: white,
        width: "90px",
        height: "45px",
        fontSize: 14,
        whiteSpace: "nowrap",
        textTransform: "none",
      },
    },
  },
  MuiCard: {
    defaultProps: {
      elevation: 1,
    },
    styleOverrides: {
      root: {
        boxShadow: boxShadowCard,
        borderRadius: 15,
        // padding: 10,  >> bad table padding
      },
    },
  },
  MuiTextField: {
    defaultProps: {
      variant: "filled",
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      root: {
        marginInline: 5,
        color: darkGrey,
        fontSize: 14,
        "&.MuiFormLabel-colorSecondary": {
          color: green,
        },
        "&.MuiFormLabel-colorError": {
          color: darkRed,
        },
      },
      shrink: {
        fontSize: 10,
      },
    },
  },
  MuiInputBase: {
    styleOverrides: {},
  },
  MuiFilledInput: {
    defaultProps: {
      disableUnderline: true,
    },
    styleOverrides: {
      underline: "none",
      input: {
        color: darkBlue,
        borderRadius: 10,
        paddingTop: 18,
        paddingRight: 15,
        paddingBottom: 8,
        paddingLeft: 15,
        "&:hover": {
          borderColor: darkBlue,
        },
        "&:active": {
          backgroundColor: "#fff",
        },
        "&:focus": {
          backgroundColor: "#fff",
        },
        "&.MuiInputBase-inputAdornedEnd": {
          paddingRight: 0,
        },
        "&.MuiInputBase-inputAdornedStart": {
          paddingLeft: 0,
        },
        "&.MuiInputBase-inputHiddenLabel": {
          paddingTop: 12,
          paddingBottom: 12,
        },
        "&:-webkit-autofill": {
          WebkitBoxShadow: "0 0 0 30px white inset !important",
        },
        "&.Mui-disabled": {
          backgroundColor: "#EDF0F5",
          borderColor: "#AFB9C9",
        },
      },
      root: {
        backgroundColor: "#fff",
        borderRadius: 10,
        border: `1px solid ${inputBorderColorDefault}`,
        color: darkGrey,
        "&:hover": {
          borderColor: inputBorderColorHover,
          backgroundColor: "#fff",
        },
        "&.MuiInputBase-colorError": {
          borderColor: darkRed,
          color: darkRed,
        },
        "&.MuiInputBase-colorSecondary": {
          borderColor: green,
          color: green,
        },
        "&.Mui-focused": {
          backgroundColor: "#fff",
        },
        "&.MuiInputBase-adornedStart": {
          paddingLeft: 8,
        },
        "&.MuiInputBase-adornedEnd": {
          paddingRight: 16,
        },
        "&.Mui-disabled": {
          backgroundColor: "#EDF0F5",
          borderColor: "#AFB9C9",
        },
      },
      multiline: {
        "& > textarea": {
          paddingTop: 0,
          paddingBottom: 8,
          paddingLeft: 4,
          paddingRight: 4,
        },
      },
    },
  },
  MuiInputAdornment: {
    styleOverrides: {
      root: {
        color: "inherit",
        "&.MuiInputAdornment-filled": {
          // backgroundColor: inputBorderColorDefault,
          backgroundColor: "transparent",
          height: "100%",
          borderRadius: 4,
          "& svg": {
            color: darkBlue,
          },
        },
      },
    },
  },
  MuiAutocomplete: {
    styleOverrides: {
      root: {
        "& .MuiAutocomplete-endAdornment": {
          top: "50%",
          padding: 0,
          transform: "translate(0, -50%)",
        },
        "& .MuiButtonBase-root-MuiIconButton-root-MuiAutocomplete-clearIndicator": {
          height: "100%",
        },
        "& .MuiAutocomplete-popupIndicator": {
          height: "fit-content",
        },
        "&:hover .MuiAutocomplete-clearIndicator": {
          height: "100%",
        },
        "& .MuiInputBase-hiddenLabel": {
          paddingTop: 5,
          paddingBottom: 5,
        },
      },
      endAdornment: {
        height: "auto",
        top: "auto",
      },
    },
  },
  MuiPaginationItem: {
    styleOverrides: {
      root: {
        "&.Mui-selected": {
          backgroundColor: bordersGrey,
        },
      },
      previousNext: {
        backgroundColor: darkBlue,
        color: white,
        "&:hover": {
          backgroundColor: darkGrey,
        },
      },
      firstLast: {
        border: "1px solid " + darkBlue,
        "&:hover": {
          backgroundColor: bordersGrey,
        },
      },
    },
  },
  MuiTypography: {
    defaultProps: {
      fontFamily: mediumFont,
    },
  },
};
