import { useTranslation } from "@health/i18n";
import { Maybe, OrderLine } from "@health/queries";
import { Box, CustomDialog, darkBlue, darkGrey, Divider, ShowButton, Typography } from "@health/ui";
import { getAuthStatusColor, orderLineAuthStatusMeta } from "pages/Prescriptions/components/utils";
import React, { FC, Fragment, useState } from "react";
import CardContent from "./AuditHistory/CardContent.component";

type MedicationsModelProps = {
  lines: Maybe<OrderLine>[];
};

export const MedicationsModel: FC<MedicationsModelProps> = ({ lines }) => {
  const { t, i18n } = useTranslation("vendor");
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(!open);
  };

  return (
    <CustomDialog
      title={t("Medications")}
      open={open}
      onCloseModal={handleClickOpen}
      maxWidth='sm'
      button={<ShowButton onClick={handleClickOpen} />}
    >
      {lines?.map(line => {
        const medication = line?.medication;

        return (
          <Fragment key={medication?.id}>
            <Box color={darkBlue} display='flex' justifyContent='space-between'>
              <Box width={300}>
                <Box m={1}>
                  <CardContent title={t("Generic Name")} subTitle={medication?.display} />
                </Box>
                {medication?.code && (
                  <Box m={1}>
                    <CardContent title={t("Code")} subTitle={medication?.code} />
                  </Box>
                )}
              </Box>
              <Box width={300}>
                {line?.quantity && (
                  <Box m={1}>
                    <CardContent title={t("Quantity")} subTitle={line?.quantity} />
                  </Box>
                )}

                <Box m={1}>
                  <CardContent title={t("Refills")} subTitle={medication?.refills} />
                </Box>
              </Box>
            </Box>

            {medication?.instructions && (
              <Box m={"0px 8px 8px"} color={darkBlue}>
                <Typography fontFamily='CoHeadlineTrial-Light'>{t("Instructions")}</Typography>
                <Typography fontSize={13} color={darkGrey}>
                  {i18n.language === "ar" ? medication?.arabicInstructions : medication?.instructions}
                </Typography>
              </Box>
            )}

            {line?.authStatus && (
              <Box m={"0px 8px 8px"} color={darkBlue}>
                <Typography fontFamily='CoHeadlineTrial-Light'> {t("Auth Status")} </Typography>
                <Typography fontSize={13} color={getAuthStatusColor(line?.authStatus)}>
                  {t(orderLineAuthStatusMeta[line.authStatus]?.label)}
                </Typography>
              </Box>
            )}
            <Divider />
          </Fragment>
        );
      })}
    </CustomDialog>
  );
};
