import { OrderStatus } from "@health/queries";
import { OrderColorEnum } from "@health/ui";

export const getTitle = t => ({
  [OrderStatus.ErxHubRejected]: t("Rejected"),
  [OrderStatus.ErxHubFullyApproved]: t("Approved"),
  [OrderStatus.WaitingErxHubApproval]: t("Pending Approval"),
  [OrderStatus.InProgress]: t("Progress"),
  [OrderStatus.ErxHubPartiallyApproved]: t("Partially Approved"),
  [OrderStatus.ErxHubTimedOut]: t("Yusur Order Timed Out"),
  [OrderStatus.ErxHubClaimApproved]: t("Claim Approved"),
  [OrderStatus.WaitingErxHubClaimApproval]: t("Waiting Claim Approval"),
  [OrderStatus.ErxHubClaimRejected]: t("Claim Rejected"),
  [OrderStatus.ErxHubClaimFailed]: t("Claim Failed"),
  [OrderStatus.ErxHubAuthRequestFailed]: t("Auth Request Failed"),
  [OrderStatus.ReadyForCustomerPickup]: t("Ready For Customer Pickup"),
  [OrderStatus.WaitingPatientConfirmation]: t("Waiting patient confirmation"),
  [OrderStatus.ErxHubClaimSubmitTimedOut]: t("Claim Submit Timed Out"),
  [OrderStatus.ErxHubAuthSubmitTimedOut]: t("Auth Submit Timed Out"),
  [OrderStatus.AcceptedByProvider]: t("Pending Allocation"),
  [OrderStatus.Allocated]: t("Allocated"),
  // Delivering
  [OrderStatus.Delivered]: t("Delivered"),
  [OrderStatus.Dispensed]: t("Dispensed"),
  [OrderStatus.OutForDelivery]: t("On The Way"),
});

export const shouldShowResubmitButton = (status: string): boolean => {
  return (
    status === OrderStatus.ErxHubPartiallyApproved ||
    status === OrderStatus.ErxHubTimedOut ||
    status === OrderStatus.ErxHubRejected ||
    status === OrderStatus.ErxHubAuthRequestFailed ||
    status === OrderStatus.ErxHubClaimSubmitTimedOut ||
    status === OrderStatus.ErxHubAuthSubmitTimedOut
  );
};
export const shouldShowCancelButton = (status: string): boolean => {
  return (
    status === OrderStatus.InProgress ||
    status === OrderStatus.WaitingErxHubApproval ||
    status === OrderStatus.ErxHubFullyApproved ||
    status === OrderStatus.ErxHubPartiallyApproved ||
    status === OrderStatus.ErxHubRejected ||
    status === OrderStatus.ErxHubTimedOut ||
    status === OrderStatus.ErxHubAuthRequestFailed ||
    status === OrderStatus.ErxHubClaimFailed ||
    status === OrderStatus.ErxHubClaimSubmitTimedOut ||
    status === OrderStatus.WaitingPatientConfirmation
  );
};

export const getColorFromStatus = status => {
  if (
    status === OrderStatus.ErxHubRejected ||
    status === OrderStatus.ErxHubAuthRequestFailed ||
    status === OrderStatus.ErxHubClaimFailed ||
    status === OrderStatus.ErxHubTimedOut ||
    status === OrderStatus.ErxHubClaimSubmitTimedOut ||
    status === OrderStatus.ErxHubAuthSubmitTimedOut
  ) {
    return OrderColorEnum.error;
  } else if (status === OrderStatus.AcceptedByProvider || status === OrderStatus.Allocated) {
    return OrderColorEnum.warning;
  } else if (status === OrderStatus.NewRequest) return OrderColorEnum.primary;
  else if (status === OrderStatus.Delivered || status === OrderStatus.OutForDelivery || status === OrderStatus.Dispensed) {
    return OrderColorEnum.info;
  } else return status === OrderStatus.WaitingErxHubApproval ? OrderColorEnum.warning : OrderColorEnum.success;
};
