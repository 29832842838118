import { bg, makeStyles, primary, white } from "@health/ui";

export const useCommunicationSelectedUserStyles = makeStyles()({
  root: {
    padding: 15,
    display: "flex",
    fontSize: 14,
    borderBottom: `1px solid ${bg}`,
  },
  selectedUserRoot: {
    borderBottom: `1px solid ${bg}`,
    background: white,
    borderRadius: `15px 15px 0 0`,
    height: 80,
    justifyContent: "center",
    alignItems: "center",
    marginRight: 1,
    marginLeft: 1,
  },
  avatar: {
    width: 60,
    height: 60,
    borderRadius: "10px",
    background: bg,
    border: `1px solid ${bg}`,
  },
  container: {
    padding: "0px 10px",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
  title: {
    fontSize: "18px",
    fontFamily: "CoHeadlineTrial-Light, Arab Kufi Medium",
  },
  massage: {
    color: primary,
    overflow: "hidden",
    display: "-webkit-box",
    maxHeight: 42,
    "-webkit-line-clamp": 1,
    "-webkit-box-orient": "vertical",
  },
});
