import { useOpenStateController } from "@health/common";
import { useTranslation } from "@health/i18n";
import { OrderDataFragment, useGetActiveDispensedPrescriptionsQuery } from "@health/queries";
import { Box, Button, CircularProgress, CustomDialog, Tooltip, turquoise, Typography } from "@health/ui";
import React, { FC } from "react";
import { useOrderActionStyles } from "./OrderAction.styles";

type PendingActiveMedicationProps = {
  patient: NonNullable<OrderDataFragment["prescription"]>["patient"];
};

export const ActiveMedicationButton: FC<PendingActiveMedicationProps> = props => {
  const { patient } = props;
  const { t } = useTranslation("vendor");
  const { classes } = useOrderActionStyles();
  const { open, handleOpen, handleClose } = useOpenStateController();

  const { data, loading } = useGetActiveDispensedPrescriptionsQuery({
    variables: {
      patientIdentifier: patient?.nationalIdNumber!,
    },
    skip: !open,
  });

  const activities = data?.getActiveDispensedPrescriptions;

  const activeMedicationButtonLabel = t("Active Medications");
  return (
    <CustomDialog
      open={open}
      submitTitle={t("Done")}
      title={activeMedicationButtonLabel}
      onCloseModal={handleClose}
      onConfirm={handleClose}
      button={
        <Tooltip title={activeMedicationButtonLabel}>
          <Button onClick={handleOpen} className={classes.button}>
            {activeMedicationButtonLabel}
          </Button>
        </Tooltip>
      }
    >
      <div>
        <Typography fontFamily='CoHeadlineTrial-Light, Arab Kufi Medium'>{t("Patient Info")}</Typography>
        <Typography variant='subtitle2'>
          {t("Name")} : {patient?.firstName + " " + patient?.lastName}
        </Typography>
        {patient?.bloodGroup && (
          <Typography variant='subtitle2'>
            {t("blood Type")} : {patient?.bloodGroup}
          </Typography>
        )}
      </div>
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          {activities?.length ? (
            <>
              <Typography color={turquoise}>{t("Medications")}</Typography>
              {activities?.map((item, index) => (
                <Box m={1} key={`${item?.overallStatus} ${index}`}>
                  <Typography>{item?.overallStatus}</Typography>
                  {item?.activities?.map((itemData, indexItem) => (
                    <Box m={1} key={`${itemData?.quantity} ${indexItem}`}>
                      <Typography fontSize='14px'>{itemData?.display}</Typography>
                      <Typography fontSize='14px'>
                        <strong>{t("Qty")}: </strong> {itemData?.quantity}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              ))}
            </>
          ) : (
            <Typography m='10px 0px' fontFamily='CoHeadlineTrial-Light, Arab Kufi Medium'>
              {t("There is no Active Medications")}
            </Typography>
          )}
        </>
      )}
    </CustomDialog>
  );
};
