import { useTranslation } from "@health/i18n";
import { AppTypes } from "@health/queries";
import React from "react";
import { ChangePasswordForm } from "../Form/ChangePasswordForm";
import { ForgetPasswordForm } from "../Form/ForgetPasswordForm";
import { LoginForm } from "../Form/LoginForm";
import { RegisterForm } from "../Form/RegisterForm";
import { ConfirmationFormComponent } from "./ConfirmationForm.component";
import { SuccessViewComponent } from "./SuccessView.component";

export const dialogStep = {
  forget: "forget-password",
  verify: "verify",
  success: "success",
  login: "login",
  changePassword: "change-password",
  resetSuccess: "reset-success",
};

export const useTabs = (handleStep, handleRouteView) => {
  const { t } = useTranslation();
  return [
    {
      name: t("Sign in"),
      value: "1",
      content: <LoginForm hasForget app={AppTypes.Customer} onChangeStep={handleStep} onRouteView={handleRouteView} />,
    },
    {
      name: t("Register Now!"),
      value: "2",
      content: <RegisterForm onChangeStep={handleStep} />,
    },
  ];
};
export const useSuccess = handleStep => {
  const { t } = useTranslation();
  return [
    {
      name: t("Thank you!"),
      value: "1",
      content: (
        <SuccessViewComponent title={t("Welcome to YusurPlus Platform")} submitTitle={t("Start and Enjoy")} onChangeStep={handleStep} />
      ),
    },
  ];
};

export const useResetSuccessfully = handleStep => {
  const { t } = useTranslation();
  return [
    {
      name: t("Password Reset Successfully"),
      value: "1",
      content: (
        <SuccessViewComponent
          title={t("Your password has been reset successfully.")}
          submitTitle={t("Back to signin")}
          onChangeStep={handleStep}
        />
      ),
    },
  ];
};

export const useForget = handleStep => {
  const { t } = useTranslation();
  return [
    {
      name: t("Forgot Password!"),
      value: "1",
      content: <ForgetPasswordForm app={AppTypes.Customer} onChangeStep={handleStep} />,
    },
  ];
};

export const useChangePassword = handleStep => {
  const { t } = useTranslation();
  return [
    {
      name: t("Reset Password!"),
      value: "1",
      content: <ChangePasswordForm onChangeStep={handleStep} />,
    },
  ];
};

export const useConfirmation = handleStep => {
  const { t } = useTranslation();
  return [
    {
      name: t("Confirmation"),
      value: "1",
      content: <ConfirmationFormComponent onChangeStep={handleStep} />,
    },
  ];
};
