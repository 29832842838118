/* eslint-disable sonarjs/cognitive-complexity */
import { useReactiveVar } from "@apollo/client";
import { UserContext } from "@health/common";
import { useTranslation } from "@health/i18n";
import {
  AppRoleTypes,
  Order,
  OrderDeliveryStatusEnum,
  OrderDirection,
  OrderSortField,
  OrderSourcesEnum,
  OrderStatus,
  PrescriptionsVendorQueryVariables,
  useBranchesQuery,
  useDataGridState,
  usePrescriptionsVendorQuery,
} from "@health/queries";
import { getTranslatedNameField, mapActiveFiltersForHeader } from "@health/ui";
import { formatDeliveryTimeSlot } from "pages/Orders/utils";
import { useContext, useState } from "react";
import { pharmacyUserPreferencesVar } from "shared/components";
import { PrescriptionsColumns } from "./PrescriptionsColumns";
import { usePrescriptionsFilter } from "./PrescriptionsFilter.components";

export const usePrescriptionsPage = () => {
  const { t, i18n } = useTranslation("vendor");
  const [activeFilters, setActiveFilters] = useState<Record<string, any>>({});

  const { user } = useContext(UserContext);
  const pharmacyUserPreferences = useReactiveVar(pharmacyUserPreferencesVar);
  const branchesIds = pharmacyUserPreferences?.branch?.map(b => b.id) || [];

  const inputs: PrescriptionsVendorQueryVariables = {
    first: 10,
    after: undefined,
    before: undefined,
    last: undefined,
    filter: {
      branches: branchesIds,
      parentOrders: false,
      source: [OrderSourcesEnum.PrescriptionDispense, OrderSourcesEnum.PrescriptionRefill, OrderSourcesEnum.CsTicket],
    },
    sortBy: {
      direction: OrderDirection.Desc,
      field: OrderSortField.Number,
    },
  };
  const { data: branchesData } = useBranchesQuery({
    variables: {
      first: 10,
    },
  });

  const branches = branchesData?.branches?.edges?.map(item => item?.node);
  const { filters: allFilters, deliveryTypeOptions } = usePrescriptionsFilter(activeFilters, branches, pharmacyUserPreferences);
  const filters = allFilters.filter(item =>
    user?.appRole == AppRoleTypes.User ? item?.name != "branches" && item?.name != "pharmacyBranch" : item
  );

  const { data, isLoading, refetch, pageSize, doChangePageSize, doSort, doFilter, doGoToNext, doGoToPrevious } = useDataGridState({
    useCustomQuery: usePrescriptionsVendorQuery,
    input: inputs,
  });

  // useEffect(() => {
  //   refetch({
  //     filter: {
  //       branches: branchesIds,
  //       parentOrders: false,
  //     },
  //   });
  // }, [branchesIds.join(",")]);
  const [columns] = useState(PrescriptionsColumns(t));
  const prescriptions = (data?.orders?.edges?.map(order => order?.node) as Order[]) || [];
  const pageInfo = data?.orders?.pageInfo;
  const totalCount = data?.orders?.totalCount;

  const handleGotoNext = () => {
    doGoToNext();
  };
  const handleGoToPrevious = () => {
    doGoToPrevious();
  };

  const handlePageSizeChange = (size: number): void => {
    doChangePageSize(size);
  };
  const handleSortData = row => {
    doSort(row.sortColumnEnum);
  };

  const getBranchData = dataBranches => {
    const getBranch = dataBranches && branches?.find(item => getTranslatedNameField(item) === dataBranches);

    if (branchesIds) return branchesIds;
    else return getBranch ? [getBranch?.id] : null;
  };
  const handleApplyFilters = data => {
    const { createdTo, status, deliveryStatus, ordersNumbers, orderType, ...rest } = data;
    const filteredStatus = status ? [OrderStatus[status.replace(/\s+/g, "")]] : [];
    const deliveryStatusData = deliveryStatus ? [OrderDeliveryStatusEnum[deliveryStatus.replace(/\s+/g, "")]] : [];
    delete rest?.pharmacyBranch;
    setActiveFilters({ ...data, [t("orderType")]: data.orderType });

    doFilter({
      ...rest,
      branches: getBranchData(data?.branches),
      deliveryStatus: deliveryStatusData || [],
      numbers: ordersNumbers && !isNaN(ordersNumbers) ? [String(ordersNumbers)] : [],
      status: filteredStatus || [],
      deliveryTimeSlot: data?.deliveryTimeSlot?.id,
      deliveryDate: data?.deliveryDate || undefined,
      isDelivery: orderType ? deliveryTypeOptions.find(item => item?.label === orderType)?.value : undefined,
      parentOrders: false,
      created:
        data?.created || createdTo
          ? {
              lte: data?.createdTo || null,
              gte: data?.created || null,
            }
          : null,
    });
  };
  const [isFilterOpened, setIsFilterOpened] = useState(false);

  const handleToggleFilters = () => {
    setIsFilterOpened(state => !state);
  };

  const pageHeaderFiltersMappers = {
    deliveryTimeSlot: value => (!value ? undefined : `${formatDeliveryTimeSlot(value?.startTime, value?.endTime, i18n.language)} `),
  };

  return {
    branches,
    columns,
    prescriptions,
    isLoading,
    pageSize,
    pageInfo,
    totalCount,
    isFilterOpened,
    filters,
    headerActiveFilters: mapActiveFiltersForHeader(activeFilters, pageHeaderFiltersMappers),
    handleToggleFilters,
    refetch,
    handleGotoNext,
    handleGoToPrevious,
    handlePageSizeChange,
    handleSortData,
    handleApplyFilters,
    activeFilters,
  };
};
