import { getAssetsFromCDN } from "@health/common";
import { useTranslation } from "@health/i18n";
import { Card, Container, Grid, Typography, useIsMobileView, useMediaQuery } from "@health/ui";
import React, { FC } from "react";
import { useEntryLayoutStyles } from "./styles/useEntryLayoutStyles";

export const EntryLayout: FC = props => {
  const { children } = props;
  const { t } = useTranslation();
  const { classes } = useEntryLayoutStyles();
  const isMatch = useMediaQuery("(max-width:700px)");
  const isMobile = useIsMobileView();
  return (
    <div className={classes.body}>
      <img src={getAssetsFromCDN("entryShadow.jpg")} alt='entry' className={classes.image} />
      <div className={classes.text}>
        <Container disableGutters={isMobile}>
          <Card className={isMobile ? classes.cardMobile : classes.card}>
            <Grid container>
              <Grid item hidden={isMatch} xs={isMatch ? 0 : 6}>
                <div className={classes.relativeImg}>
                  <img src={getAssetsFromCDN("entryCard.jpg")} alt='cardImag' className={classes.imageCard} />
                  <div className={classes.container}>
                    <div className={classes.border} />
                    <Typography className={classes.textContainer}>
                      <strong>{t("Yusur")}</strong>
                      {t(
                        " is a platform that will serve to pick up the e-Rx from Yusur hub, check availability of medications at selected pharmacies, and connecting pickup of medication point to a pool of couriers that will pick up the order from selected  to the members"
                      )}
                    </Typography>
                  </div>
                </div>
              </Grid>
              <Grid item xs={isMatch ? 12 : 6}>
                {children}
              </Grid>
            </Grid>
          </Card>
        </Container>
      </div>
    </div>
  );
};
