import { Avatar, Box, CustomIcon, Typography } from "@health/ui";
import moment from "moment";
import React, { FC } from "react";
import { isMobile } from "react-device-detect";
import { useCommunicationItemStyles } from "./CommunicationItem.styles";
import { CommunicationItemProps } from "./CommunicationItem.types";

export const CommunicationItem: FC<CommunicationItemProps> = ({
  pharmacyName,
  lastMessage,
  time,
  selectedItem,
  avatar = <CustomIcon icon={"changeUser"} color={"#fff"} width={60} height={60} viewBox='0 0 60 60' />,
  haveNewMsg,
  unReadMessages,
  onClick,
}) => {
  const { classes } = useCommunicationItemStyles();

  const handleClick = () => {
    onClick?.();
  };

  return (
    <Box className={`${selectedItem && classes.selected} ${{ haveNewMsg } && classes.root1} ${classes.root}`} onClick={handleClick}>
      <div>
        <Avatar className={classes.avatar} src={avatar || ""} />
      </div>
      {!isMobile && (
        <Box className={classes.container}>
          <Box>
            <Typography fontSize='16px' fontFamily='CoHeadlineTrial-Light'>
              {pharmacyName || ""}
            </Typography>
            <Typography>{lastMessage || ""}</Typography>
            <Typography fontSize='10px'>{time ? moment(time).fromNow() : ""}</Typography>
          </Box>
          {!selectedItem && Boolean(unReadMessages) && <div className={classes.countUnReadMsg}>{unReadMessages}</div>}
        </Box>
      )}
    </Box>
  );
};
