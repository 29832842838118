import { darkBlue, greyVendor, makeStyles, white } from "@health/ui";

export const useDispenseModelStyles = makeStyles()(() => ({
  paper: {
    width: 536,
    height: "fit-content",
    borderRadius: 15,
  },
  box: {
    padding: 10,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  title: {
    fontSize: 20,
    fontFamily: "CoHeadlineTrial-Light, Arab Kufi Medium",
    marginBottom: 0,
  },
  id: {
    fontSize: 28,
    fontFamily: "CoHeadlineTrial-Light, Arab Kufi Medium",
    marginBottom: 0,
  },
  print: {
    width: 76,
  },
  ok: {
    width: 53,
    color: darkBlue,
    border: "1px solid",
    background: white,
    "&:hover": {
      color: white,
    },
  },
  action: {
    height: 70,
    padding: 10,
    display: "flex",
    alignItems: "center",
    boxShadow: "0px 0px 6px #0000001A",
  },
  width: {
    width: "100%",
  },
  line: {
    marginTop: 10,
    marginBottom: 10,
    background: greyVendor,
    opacity: 0.2,
  },
  container: {
    position: "relative",
  },
  logo: {
    height: 55,
    padding: 5,
    background: white,
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  button: {
    fontSize: 13,
    whiteSpace: "nowrap",
  },
}));
