import { useOpenStateController } from "@health/common";
import { useTranslation } from "@health/i18n";
import { Branch, PharmacyCredentials } from "@health/queries";
import { Button, CustomDialog, Grid, ShowButton } from "@health/ui";
import { YusurCredentialForm } from "pages/Branches/form/components/YusurCredentialForm";
import { FC } from "react";
import { FormProvider } from "react-hook-form";
import { formGirdSpacing } from "shared/constants";
import { useYusurCredentialsHook } from "./YusurCredentials.hooks";

export const YusurCredentialsDialog: FC<{ row: Branch }> = ({ row }) => {
  const id = row?.id;
  const { t } = useTranslation("vendor");
  const { open, handleToggle, handleClose, handleOpen } = useOpenStateController();

  const { onSubmit, handleSubmit, methods, errors, dataCredential } = useYusurCredentialsHook(id, handleToggle, open);
  return (
    <>
      <ShowButton onClick={handleOpen} />
      {open && (
        <FormProvider {...methods}>
          <CustomDialog
            open={open}
            onCloseModal={handleClose}
            title={t("Yusur Credentials")}
            DialogContentProps={{
              sx: { minHeight: "10vh" },
            }}
            footerComponent={
              <>
                <Button
                  color={"primary"}
                  type='submit'
                  form='custom-dialog-form'
                  size='large'
                  variant='contained'
                  onClick={handleSubmit(onSubmit)}
                >
                  {t("Save")}
                </Button>
                <Button variant='contained' size='large' color={"inherit"} onClick={handleClose}>
                  {t("Cancel")}
                </Button>
              </>
            }
          >
            <Grid container spacing={formGirdSpacing}>
              <Grid item xs={12}>
                <YusurCredentialForm errors={errors} defaultValues={dataCredential as PharmacyCredentials} />
              </Grid>
            </Grid>
          </CustomDialog>
        </FormProvider>
      )}
    </>
  );
};
